import useDocumentTitle from "app/hooks/useDocumentTitle";
import FarmDashboard from "profit_and_loss/components/FarmDashboard";
import ResponsiveBlankSlate from "profit_and_loss/components/ResponsiveBlankSlate";
import { withProfitAndLossData } from "profit_and_loss/hoc/withProfitAndLossData";
import React from "react";

import App from "layout/app";
import ProfitAndLossDashboardWrapper from "modules/profit_and_loss/components/ProfitAndLossDashboardWrapper";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const ProfitAndLossDashboardContent = ({ data: { error, loading, profitAndLossData } }) => {
  useDocumentTitle("Profit & Loss");

  if (!loading && error) {
    App.notify("An unexpected error occurred");
  }

  return (
    <LoadingWrapper isLoading={loading && !profitAndLossData}>
      {profitAndLossData && <FarmDashboard data={profitAndLossData} />}
      {!profitAndLossData && !error && (
        <ResponsiveBlankSlate
          copy="Once you create your crops, you will see a detailed summary of your expenses, revenue, and profit / loss."
          header="Get started by creating crops, logging activities, and logging contracts"
        />
      )}
    </LoadingWrapper>
  );
};

const ProfitAndLossDashboard = (props) => (
  <ProfitAndLossDashboardWrapper {...props}>
    {withProfitAndLossData(ProfitAndLossDashboardContent)}
  </ProfitAndLossDashboardWrapper>
);

export default ProfitAndLossDashboard;
