import { useSuspenseQuery } from "@apollo/client";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import _ from "lodash";
import moment from "moment/moment";
import React, { useMemo, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { ReportError } from "reports/components/ReportError";
import ReportSidebar from "reports/containers/ReportSidebar";
import ReportTable from "reports/containers/ReportTable";

import { getReportDefinitions } from "collection/graphql/reports";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import App from "layout/app";
import { fetch } from "lib/ajax";
import { downloadFile } from "lib/download/downloadFile";
import { REPORT_DOWNLOAD } from "lib/metrics/events";
import { getFeatureNameFromReportId } from "modules/reports/utils/getFeatureNameFromReportId";

import ReportsBlankSlatePage from "components/advertisements/pages/ReportsBlankSlatePage";
import { Button } from "components/fl-ui";
import BlankSlate from "components/fl-ui/BlankSlate";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ContentMain from "components/fl-ui/Layout/ContentMain";
import ContentSidebar from "components/fl-ui/Layout/ContentSidebar";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankSlate from "components/fl-ui/RestrictedAccessBlankSlate";
import Card from "components/ui/card";
import Loading from "components/ui/loading";

const ReadyState = ({ title }) => (
  <BlankSlate icon="reports" title={title}>
    Please set filters for your report and then click the load report button to generate a preview.
  </BlankSlate>
);

const dropdownStyle = {
  position: "absolute",
  zIndex: "10",
  marginTop: "2.75rem",
};

const DATE_FORMAT = "YYYY-MM-DD";

const getDefaultForType = (type) => {
  // hook to set a default value if one is not supplied by the backend
  if (type === "date_range") {
    const endDate = moment().format(DATE_FORMAT);
    const startDate = moment().subtract(1, "year").format(DATE_FORMAT);
    return [startDate, endDate].join();
  }
  return undefined;
};

const getDefaultParameters = (parameters) => {
  return parameters.reduce((defaults, p) => {
    const param = { ...p };
    if (_.isNil(param.default) && param.required) {
      param.default = getDefaultForType(param.type);
    }
    defaults[param.id] = param.default || undefined;
    return defaults;
  }, {});
};

const ReportDetail = ({ error, loading }) => {
  const { reportId } = useParams();
  const [links, setLinks] = useState([]);
  const [linksShown, setLinksShown] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [reloadCount, setReloadCount] = useState(0);
  useDocumentTitle("Report");

  const { definitions } = useSuspenseQuery(getReportDefinitions).data;
  const definition = useMemo(() => {
    return definitions.find((definition) => {
      return definition.id === reportId && !definition.beta;
    });
  }, [definitions]);

  const [parameters, setParameters] = useState(() => {
    return definition ? getDefaultParameters(definition.parameters) : {};
  });

  const onChange = ({ parameters }) => {
    const nonJsonLinks = _.reject(definition.formats, (format) => format === "json");
    const updatedLinks = nonJsonLinks.map((format) => {
      const queryParams = new URLSearchParams(
        _.toPairs({
          id: reportId,
          format,
          ...parameters,
        })
      );

      return {
        format,
        path: `/v2.0/planner/reports/download?${queryParams.toString()}`,
      };
    });
    setLinks(updatedLinks);
    setParameters(parameters);
  };

  const { loadingAccess, showPayGate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: getFeatureNameFromReportId(reportId),
    permissionName: "reports",
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ReportError />;
  }

  if (!definition) {
    return <Navigate to="/reports" />;
  }

  const handleReload = (parameters) => {
    setReloadCount(reloadCount + 1);
    onChange({ parameters });
  };

  const handleClick = async (path) => {
    setIsDownloading(true);
    await fetch(path)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Report fetch error: ${response.statusText}`);
        }
        downloadFile(response, "report");
      })
      .catch((error) => {
        App.notify("Report download failed: ", error);
        throw error;
      })
      .finally(() => {
        setIsDownloading(false);
        handleTrack();
      });
  };

  const handleTrack = () => {
    REPORT_DOWNLOAD.track({
      report_name: definition.title,
      type: "pdf",
    });
  };

  const toggleShowLinks = (e) => {
    e.stopPropagation();
    setLinksShown(!linksShown);
  };

  const hideLinks = () => {
    setLinksShown(false);
  };

  return (
    <Container onClick={hideLinks}>
      <LoadingWrapper isLoading={loadingAccess}>
        <Header backLink="/reports" title={definition.title}>
          {links.length > 0 && (
            <Button
              disabled={isDownloading}
              color="primary"
              icon="chevronDown"
              iconRight
              loading={isDownloading}
              onClick={toggleShowLinks}
            >
              <span style={{ paddingRight: "1em" }}>{isDownloading ? "Downloading" : "Download"}</span>
            </Button>
          )}

          {linksShown && (
            <div style={dropdownStyle}>
              <Card className="border-caret-white caret-middle">
                <div style={{ padding: "0 1.5rem" }}>
                  {links.map((x) => (
                    <Button color="primary" display="block" key={x.format} link onClick={() => handleClick(x.path)}>
                      {x.format.toUpperCase()}
                    </Button>
                  ))}
                </div>
              </Card>
            </div>
          )}
        </Header>
        {showPayGate && <ReportsBlankSlatePage />}
        {showRestricted && <RestrictedAccessBlankSlate />}
        {showContent && (
          <Content>
            <ContentSidebar>
              <ReportSidebar definition={definition} onDataSourceChange={handleReload} />
            </ContentSidebar>

            <ContentMain>
              {reloadCount === 0 ? (
                <ReadyState title={definition.title} />
              ) : (
                <ReportTable definition={definition} id={reportId} parameters={parameters} />
              )}
            </ContentMain>
          </Content>
        )}
      </LoadingWrapper>
    </Container>
  );
};

export default ReportDetail;
