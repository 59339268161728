import { useMutation } from "@apollo/client";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import React, { useState } from "react";

import { DELETE_FINANCIAL_TRANSACTIONS } from "collection/graphql/profit_and_loss/mutations";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import App from "layout/app";
import ExpenseRevenueDashboard from "modules/profit_and_loss/components/ExpenseRevenueDashboard";
import LogExpenseRevenueModalContainer from "modules/profit_and_loss/components/LogExpenseRevenueModalContainer";
import { LeftButton } from "modules/profit_and_loss/styles";

import OtherExpensesAndRevenueBlankSlateBanner from "components/advertisements/banners/OtherExpensesAndRevenueBlankSlateBanner";
import ProfitAndLossBlankSlateBannerPage from "components/advertisements/pages/ProfitAndLossBlankSlateBannerPage";
import CropYearPicker from "components/fl-ui/CropYearPicker";
import { HeaderControls } from "components/fl-ui/HeaderControls";
import Container from "components/fl-ui/Layout/Container";
import Header from "components/fl-ui/Layout/Header";
import NavBar from "components/fl-ui/NavBar";
import RestrictedAccessBlankSlate from "components/fl-ui/RestrictedAccessBlankSlate";

const ExpenseRevenueDashboardContainer = () => {
  useDocumentTitle("Other Expenses & Revenue");
  const [expensesAndRevenueModalData, setExpensesAndRevenueModalData] = useState(null);
  const [selection, setSelection] = useState(new Set());
  const [deleteFinancialAccountTransactions] = useMutation(DELETE_FINANCIAL_TRANSACTIONS, {
    refetchQueries: ["GetTransactions"],
  });

  const { showPayGate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "profit_and_loss",
    permissionName: "profit_and_loss",
  });

  const onSelect = (selected) => {
    if (Array.isArray(selected)) {
      setSelection(new Set(selected));
    } else {
      const currentSelection = new Set(selection);
      currentSelection.has(selected) ? currentSelection.delete(selected) : currentSelection.add(selected);
      setSelection(currentSelection);
    }
  };

  const handleBulkDelete = async () => {
    const ids = [...selection].map(({ id }) => id);

    try {
      await deleteFinancialAccountTransactions({ variables: { ids } });
      onSelect([]);
    } catch (err) {
      App.notify("An error occurred, please try again later");
      throw err;
    }
  };

  const confirmDelete = (type) =>
    App.confirm({
      confirm: `Delete ${selection.size} ${type.toLowerCase()} ${selection.size === 1 ? "item" : "items"}`,
      message:
        "This will delete the records and remove them from any accounts they were associated with. This action cannot be undone.",
      title: `Are you sure you want to delete ${selection.size} ${type.toLowerCase()} ${
        selection.size === 1 ? "item" : "items"
      }`,
    }).then(handleBulkDelete);

  return (
    <Container>
      <Header title="Other Expenses & Revenue">
        {showContent && (
          <HeaderControls>
            <LeftButton
              color="primary"
              hollow
              onClick={() => setExpensesAndRevenueModalData({ account: { accountType: "REVENUE" } })}
            >
              Log revenue
            </LeftButton>
            <LeftButton
              color="primary"
              onClick={() => setExpensesAndRevenueModalData({ account: { accountType: "EXPENSE" } })}
            >
              Log expense
            </LeftButton>
            <CropYearPicker />
          </HeaderControls>
        )}
      </Header>

      {showPayGate && <ProfitAndLossBlankSlateBannerPage banner={OtherExpensesAndRevenueBlankSlateBanner} />}
      {showRestricted && <RestrictedAccessBlankSlate />}

      {showContent && (
        <NavBar
          tabsArray={[
            {
              component: (
                <ExpenseRevenueDashboard
                  onBulkDelete={() => confirmDelete("EXPENSE")}
                  onSelect={onSelect}
                  selection={selection}
                  setExpensesAndRevenueModalData={setExpensesAndRevenueModalData}
                  type="EXPENSE"
                />
              ),
              label: "Expenses",
              route: "expenses",
            },
            {
              component: (
                <ExpenseRevenueDashboard
                  onBulkDelete={() => confirmDelete("REVENUE")}
                  onSelect={onSelect}
                  selection={selection}
                  setExpensesAndRevenueModalData={setExpensesAndRevenueModalData}
                  type="REVENUE"
                />
              ),
              label: "Revenue",
              route: "revenue",
            },
          ]}
        />
      )}

      {expensesAndRevenueModalData && (
        <LogExpenseRevenueModalContainer
          data={expensesAndRevenueModalData}
          onClose={() => setExpensesAndRevenueModalData(null)}
        />
      )}
    </Container>
  );
};

export default ExpenseRevenueDashboardContainer;
