import futuresTabStyles from "./futuresTabStyles";
import { StyleSheet } from "aphrodite/no-important";

import { UIColors } from "components/fl-ui/colors/index";
import { Typography, Spacing, Mixins } from "components/fl-ui/constants/index";

const localBidsStyles = StyleSheet.create({
  // Index tab bar
  tabBar: {
    borderBottom: "1px solid #ddd",
    marginBottom: "2em",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    background: "linear-gradient(90deg, #fff 0%, rgba(255,255,255,0.75) 90%, rgba(255,255,255,0) 100%)",
  },
  tabBarContainer: {
    minWidth: "400px",
  },
  tabContentWrapper: {
    padding: "48px 24px",
  },

  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },

  // missing zip code blank slate
  blankslateContainer: {
    alignItems: "center",
    display: "flex",
    flexFlow: "column",
    textAlign: "center",
  },
  blankslateText: {
    lineHeight: Spacing.xlarge,
    maxWidth: Mixins.toRem(600),
    fontSize: Typography.sizes.lg,
    marginBottom: Spacing.large,
  },
  blankslateLink: {
    color: `${UIColors.blue}`,
    textDecoration: "none",
  },
  // promoView blank slate
  promoText: {
    lineHeight: `${Typography.sizes.xxl}`,
    margin: `${Spacing.xlarge} 0`,
  },
  promoFooterText: {
    fontStyle: "italic",
    color: `${UIColors.light}`,
  },

  // CashBids - remove after CashBids is removed
  buyerName: {
    fontSize: Typography.sizes.lg,
    marginBottom: 0,
  },
  buyerLocation: {
    ...Typography.infoText,
    fontSize: Typography.sizes.lg,
  },
  contractInfo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  contractInfoText: {
    marginLeft: Spacing.small,
    marginBottom: 0,
  },

  // AreaBids
  elevatorLink: {
    ":hover": {
      textDecoration: "none",
    },
  },
  elevatorName: {
    fontSize: Typography.sizes.rg,
    fontWeight: Typography.weights.medium,
    lineHeight: 1.57,
    color: UIColors.blue,
    marginTop: 0,
    marginBottom: Spacing.xxsmall,
  },
  elevatorDetails: {
    fontSize: Typography.sizes.mn,
    lineHeight: 1.46,
    marginBottom: 0,
  },
  tableHelpText: {
    ...Typography.helpText,
    display: "block",
    fontSize: Typography.sizes.sm,
  },

  // BuyerDetail
  detail_buyerName: {
    fontSize: Typography.sizes.xl,
    lineHeight: 1.5,
    marginTop: Spacing.large,
    marginBottom: Spacing.xxsmall,
  },
  detail_buyerInfo: {
    lineHeight: 1.357,
    marginTop: 0,
    marginBottom: Spacing.xxsmall,
  },
  detail_HR: {
    marginTop: Spacing.large,
    marginBottom: Spacing.large,
  },
  // utils
  inlineBlock: {
    display: "inline-block",
  },
  displayBlock: {
    display: "block",
  },
  lightText: {
    fontWeight: Typography.weights.light,
  },
  boldText: {
    fontWeight: Typography.weights.medium,
  },
});

export { localBidsStyles, futuresTabStyles };
