import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { UIColors } from "components/fl-ui/colors";
import { Borders, Spacing, Typography } from "components/fl-ui/constants";

const MetricsValue = styled.span`
  font-size: ${Typography.sizes.lg};
  font-weight: ${Typography.weights.medium};
  margin-bottom: ${Spacing.xxsmall};

  @media only screen and (min-width: 30em) and (max-width: 62.5em) {
    font-size: ${Typography.sizes.rg};
  }
  @media only screen and (max-width: 30em) {
    font-size: ${Typography.sizes.sm};
  }
`;

const MetricsLabel = styled(MetricsValue)`
  color: ${UIColors.light};
  font-size: ${Typography.sizes.rg};
  font-weight: ${Typography.weights.regular};
`;

export const ResponsiveFigure = styled(({ className, label, value }) => (
  <div className={className}>
    <MetricsValue>{value}</MetricsValue>
    <MetricsLabel>{label}</MetricsLabel>
  </div>
))`
  display: flex;
  flex-flow: column;
  max-width: 33%;
  padding: ${Spacing.xxsmall} ${Spacing.xsmall};

  border-right: ${Borders.regular};
  &:last-child {
    border-right: none;
  }
`;

ResponsiveFigure.propTypes = {
  label: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const PageSummary = styled.div`
  align-items: center;
  border-bottom: ${Borders.regular};
  border-top: ${Borders.regular};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: ${Spacing.small} 0;
  padding: ${Spacing.small} 0;
  text-align: center;

  justify-content: unset;
  > * {
    min-width: ${(props) => 100 / props.children.length}%;
  }

  @media print {
    display: none;
  }
`;

PageSummary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default PageSummary;
