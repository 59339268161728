import _ from "lodash";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useMarketingAdvisor from "hooks/useMarketingAdvisor";
import usePermissions from "hooks/usePermissions";

const useFeatureAndPermissionAccess = ({ featureName, permissionName }) => {
  const hasFeature = useEnterpriseFeature(featureName);
  const hasReadPermission = usePermissions().canRead(permissionName);
  const { isMarketingAdvisor } = useMarketingAdvisor();

  const loadingAccess = [hasFeature, hasReadPermission, isMarketingAdvisor].some(_.isNil);

  return {
    loadingAccess,
    showPayGate: !loadingAccess && !hasFeature && !isMarketingAdvisor,
    showRestricted: !loadingAccess && ((hasFeature && !hasReadPermission) || (!hasFeature && isMarketingAdvisor)),
    showContent: !loadingAccess && hasFeature && hasReadPermission,
  };
};

export { useFeatureAndPermissionAccess };
