import useDocumentTitle from "app/hooks/useDocumentTitle";
import _ from "lodash";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import ProductAndMachineMatching from "settings/pages/ProductAndMachineMatching";
import { Container, ResponsiveHeading, StyledText } from "settings/styledComponents";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import useIntegrations from "hooks/useIntegrations";
import usePermissions from "hooks/usePermissions";
import BushelIntegration from "modules/settings/components/Integrations/components/BushelIntegration";
import CNHIndustrialIntegration from "modules/settings/components/Integrations/components/CNHIndustrialIntegration";
import ClimateFieldViewIntegration from "modules/settings/components/Integrations/components/ClimateFieldViewIntegration";
import JohnDeereIntegration from "modules/settings/components/Integrations/components/JohnDeereIntegration";

import ConnectionsBlankSlateBanner from "components/advertisements/banners/ConnectionsBlankSlateBanner";
import { Stack } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankSlate from "components/fl-ui/RestrictedAccessBlankSlate";
import { Spacing } from "components/fl-ui/constants";

const ConnectionsPage = () => {
  useDocumentTitle("Connections");
  const hasIntegrationsFeature = useEnterpriseFeature("integrations");
  const { canRead } = usePermissions();
  const canReadIntegrations = canRead("integrations");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const knownIntegrations = new Map([
    ["bushel", BushelIntegration],
    ["climate", ClimateFieldViewIntegration],
    ["cnhi", CNHIndustrialIntegration],
    ["myjohndeere", JohnDeereIntegration],
  ]);
  const { integrations, loading } = useIntegrations();
  const hasConfiguredIntegrations = _.some(integrations, "enabled");
  const specifiedIntegration = searchParams.get("integration_id");
  const integrationName = ["bushel", "climate", "myjohndeere"].includes(specifiedIntegration)
    ? specifiedIntegration
    : "";

  useEffect(() => {
    if (specifiedIntegration) {
      navigate("/settings/connections", { replace: true });
    }
  }, [specifiedIntegration]);

  return canReadIntegrations ? (
    <Stack space={Spacing.xxlarge}>
      <div>
        <Row>
          <Col xs={12}>
            <Container>
              <div>
                <ResponsiveHeading>Connections</ResponsiveHeading>
                {hasIntegrationsFeature && (
                  <StyledText>
                    Connect your Bushel Farm account to other systems for ongoing data synchronization via API
                    connection.
                  </StyledText>
                )}
              </div>
            </Container>
          </Col>
        </Row>

        <LoadingWrapper isLoading={loading}>
          {integrations?.map((integration) => {
            if (!knownIntegrations.has(integration.id)) {
              console.warn(`Unsupported integration: ${integration.id}`);
              return null;
            }
            const IntegrationComponent = knownIntegrations.get(integration.id);
            const initialModalId = integration.id === integrationName ? "manualImport" : "";

            return (
              <IntegrationComponent initialModalId={initialModalId} integration={integration} key={integration.id} />
            );
          }) ?? null}
        </LoadingWrapper>
      </div>

      {hasConfiguredIntegrations && (
        <div>{hasIntegrationsFeature ? <ProductAndMachineMatching /> : <ConnectionsBlankSlateBanner />}</div>
      )}
    </Stack>
  ) : (
    <RestrictedAccessBlankSlate />
  );
};

ConnectionsPage.tabId = "connections";

export default ConnectionsPage;
