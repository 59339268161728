import React from "react";
import styled from "styled-components";

import { StyleSheet } from "components/fl-ui/aphrodite";
import { UIColors } from "components/fl-ui/colors";
import { Borders, Spacing, Typography, BorderRadius } from "components/fl-ui/constants";

const StyledHeader = styled.header`
  ${(props) => !props.borderless && `border-bottom: ${Borders.regular};`}
  display: flex;
  justify-content: space-between;

  > h3 {
    font-size: ${Typography.sizes.sm};
    margin-top: 0;
    text-transform: uppercase;
  }
`;
export const SectionHeader = ({ label, ...props }) => {
  return (
    <StyledHeader {...props}>
      <h3>{label}</h3>
    </StyledHeader>
  );
};

export const Label = styled.span`
  font-weight: 500;
`;

const StyledDefinition = styled.div`
  border-bottom: ${Borders.regular};
  min-width: 240px;
  padding: 20px 0;
  ${(props) => props.inline && "display: flex;"}
  ${(props) => props.inline && "justify-content: space-between;"}
`;
export const Definition = ({ children, inline = false, term }) => {
  return (
    <StyledDefinition inline={inline}>
      <Label>{term}</Label>
      {children}
    </StyledDefinition>
  );
};

export const StyledGrid = styled.section`
  display: grid;
  grid-gap: 0 12px;
  grid-template-columns: repeat(2, minmax(min-content, 1fr));
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, minmax(min-content, 1fr));
  }
  margin-top: 0;
`;

export const StyledTableColumn = styled.td`
  border-bottom: ${Borders.regular};
  padding: ${Spacing.regular} 0;
  :last-child {
    text-align: right;
  }
`;

const styles = StyleSheet.create({
  aarIcon: {
    color: UIColors.green,
    display: "inline-block",
    height: Spacing.regular,
    marginLeft: Spacing.xxsmall,
    width: Spacing.regular,
  },
  aarIndicator: {
    alignItems: "flex-end",
    display: "flex",
  },
  aarThumbnail: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  attachmentBlankslate: {
    background: "#fdfdfd",
    border: Borders.regular,
    borderRadius: BorderRadius.medium,
    display: "flex",
    fontSize: Typography.sizes.rg,
    justifyContent: "center",
  },
  blueColor: {
    color: UIColors.blue,
  },
  boldText: {
    fontWeight: Typography.weights.medium,
    marginBottom: Spacing.xsmall,
  },
  bottomMarginSmall: {
    marginBottom: Spacing.small,
  },
  buttonBorder: {
    border: Borders.dark,
    borderRadius: BorderRadius.medium,
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
  },
  chooserCardContainer: {
    borderBottom: Borders.regular,
    paddingBottom: Spacing.regular,
  },
  chooserCard: {
    marginTop: Spacing.xsmall,
    padding: `${Spacing.xsmall} ${Spacing.regular} 0`,
  },
  chooserCardDetail: {
    alignItems: "center",
    display: "flex",
    marginRight: Spacing.regular,
    width: "60%",
    "@media only screen and (max-width: 48.75em)": {
      width: "auto !important",
      marginRight: "0 !important",
    },
  },
  chooserCardFooter: {
    backgroundColor: "#fdfdfd",
    borderBottom: Borders.regular,
    borderRadius: `0 0 ${BorderRadius.medium} ${BorderRadius.medium}`,
    borderTop: Borders.regular,
    margin: `0 -${Spacing.regular}`,
    padding: `${Spacing.large} ${Spacing.regular} ${Spacing.xsmall}`,
  },
  clickable: {
    cursor: "pointer",
  },
  areaDefinition: {
    flex: "0 0 50%",
  },
  areaActions: {
    flex: "0 0 60%",
    marginTop: `-${Spacing.small}`,
  },
  chooserCardLeftButton: {
    marginRight: Spacing.xxsmall,
    paddingRight: Spacing.regular,
  },
  copyText: {
    lineHeight: 1.3,
    marginBottom: Spacing.xxsmall,
    ":first-child": {
      marginTop: Spacing.xsmall,
    },
  },
  dropDownButton: {
    marginRight: `-${Spacing.minor}`,
    minWidth: Spacing.xxxlarge,
    padding: Spacing.xxsmall,
  },
  durationDropdownButton: {
    display: "flex",
    fontWeight: Typography.weights.regular,
    padding: `0 ${Spacing.xxsmall} ${Spacing.xsmall}`,
  },
  editButton: {
    color: UIColors.blue,
    marginRight: Spacing.xsmall,
  },
  errorText: {
    color: UIColors.red,
    fontSize: Typography.sizes.mn,
  },
  fieldCropChooserMenu: {
    background: UIColors.secondary,
    borderBottom: Borders.regular,
    marginTop: "-4px",
    padding: `${Spacing.xxsmall} ${Spacing.small} 0`,
  },
  fieldCropChooserMenuItem: {
    fontSize: Typography.sizes.sm,
    textTransform: "uppercase",
  },
  noMatchingCrops: {
    padding: Spacing.small,
  },
  greenText: {
    color: UIColors.green,
  },
  inputsViewButton: {
    color: UIColors.regular,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: Spacing.regular,
    width: "100%",
  },
  listSpacing: {
    marginTop: Spacing.small,
  },
  mapImage: {
    background: "white",
    border: Borders.regular,
    borderRadius: BorderRadius.medium,
    height: Spacing.xxxlarge,
    marginBottom: Spacing.small,
    marginRight: Spacing.regular,
    marginTop: Spacing.small,
    width: Spacing.xxxlarge,
  },
  noCropText: {
    fontWeight: Typography.weights.medium,
    marginTop: Spacing.regular,
  },
  optionContainer: {
    background: UIColors.white,
    borderBottom: Borders.regular,
    height: "5.5rem",
  },
  optionHeader: {
    fontSize: Typography.sizes.rg,
    marginBottom: Spacing.xxsmall,
  },
  optionSubText: {
    fontSize: Typography.sizes.mn,
    marginBottom: Spacing.xxsmall,
  },
  playIcon: {
    color: UIColors.white,
    height: Spacing.large,
    position: "absolute",
    width: Spacing.large,
    top: "50%",
    right: "50%",
    transform: "translate(10px, -5px)",
  },
  productInputContainer: {
    border: Borders.regular,
    borderRadius: BorderRadius.medium,
    margin: `${Spacing.regular} 0`,
    padding: Spacing.small,
    backgroundColor: "red",
  },
  redText: {
    color: UIColors.red,
  },
  reverseRow: {
    flexFlow: "row-reverse",
  },
  rightBorder: {
    borderRight: Borders.regular,
  },
  rightMargin: {
    marginRight: Spacing.regular,
  },
  roundedImage: {
    border: Borders.regular,
    borderRadius: BorderRadius.medium,
    objectFit: "contain",
  },
  rowContainer: {
    display: "flex",
    marginBottom: Spacing.large,
  },
  selectedBorder: {
    border: "1px solid #5079a4",
  },
  selectedThumbnail: {
    boxShadow: `${UIColors.blue} 0 0 0 3px`,
  },
  smallText: {
    fontSize: Typography.sizes.sm,
    fontWeight: Typography.weights.regular,
    marginBottom: Spacing.xsmall,
    textTransform: "uppercase",
  },
  thumbnailContainer: {
    cursor: "pointer",
    display: "flex",
    flexFlow: "wrap",
  },
  thumbnails: {
    border: "none",
    borderRadius: BorderRadius.medium,
    marginTop: Spacing.small,
  },
  truncatedText: {
    marginRight: Spacing.xsmall,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  unitButton: {
    margin: `${Spacing.xxsmall} 0`,
  },
  unitErrorContainer: {
    alignItems: "center",
    background: "#fef7f7",
    border: `${UIColors.red} 1px solid`,
    borderRadius: BorderRadius.medium,
    display: "flex",
    justifyContent: "space-between",
    margin: `0 0 ${Spacing.xxsmall}`,
  },
  unitErrorIcon: {
    color: "#E3684C",
    height: Spacing.large,
    margin: Spacing.minor,
    width: Spacing.large,
  },
  unitErrorText: {
    fontSize: Typography.sizes.mn,
    fontWeight: Typography.weights.medium,
  },
  verticallyCentered: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  verticallyCenteredAdjust: {
    "@media only screen and (max-width: 48.75em)": {
      flexWrap: "wrap !important",
    },
  },
});

export default styles;
