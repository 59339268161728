import _ from "lodash";
import { isNative } from "mobile/mobileManager";
import PropTypes from "prop-types";
import React, { useState } from "react";

import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import usePermissions from "hooks/usePermissions";
import useReleaseFeature from "hooks/useReleaseFeature";
import App from "layout/app";
import { SETTINGS_MOBILE_CONNECT_MODAL } from "lib/metrics/events";
import { LeftButton } from "modules/profit_and_loss/styles";
import DataIntegrationsModal from "modules/settings/components/DataIntegrationsModal";
import ImportConfig from "modules/settings/components/ImportConfig";
import CommonIntegrationDataRow from "modules/settings/components/Integrations/components/CommonIntegrationDataRow";
import ConnectWarningModal from "modules/settings/components/Integrations/components/ConnectWarningModal";
import {
  ACREAGE_PAYGATE,
  CONNECT_WARNING,
  INTEGRATIONS_PAYGATE,
} from "modules/settings/components/Integrations/constants";
import useIntegration from "modules/settings/components/Integrations/hooks/useIntegration";
import useIntegrationConnectionLink from "modules/settings/components/Integrations/hooks/useIntegrationsConnectionLink";
import useModalState from "modules/settings/components/Integrations/hooks/useModalState";
import { AUTOMATIC_IMPORT, MANUAL_IMPORT } from "modules/settings/lib/constants";
import { StyledText } from "modules/settings/styledComponents";

import ConnectionsBlankSlateBanner from "components/advertisements/banners/ConnectionsBlankSlateBanner";
import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import PayGateCommonBlankSlateModal from "components/advertisements/modals/PayGateCommonBlankSlateModal";
import { Button } from "components/fl-ui";

const MANUAL_IMPORT_SUCCESS = "manualImportSuccess";

const CommonIntegration = ({
  automaticFieldImportText,
  automaticModalCopy,
  integrationImgSrc,
  integration,
  mainCopy,
  manualFieldImportText,
}) => {
  const { enabled, id, name, provider } = integration;

  const {
    config,
    importByDateRange,
    isLoadingConfig,
    isSaving,
    revoke,
    updateImportConfig,
    importFieldFromMachineDataProvider,
  } = useIntegration(id);

  const hasManualImportFeature = useReleaseFeature("manual-import");
  const hasMachineData = useEnterpriseFeature("machine_data");

  const { activeModal, protectCallback, setActiveModal } = useModalState(MANUAL_IMPORT);
  const [formData, setFormData] = useState(null);

  const showButtons = usePermissions().canWrite("integrations");
  const showAutomaticImport = protectCallback(() => handleShowModal(AUTOMATIC_IMPORT));
  const showManualImport = protectCallback(() => handleShowModal(MANUAL_IMPORT));

  const { connect, disabled } = useIntegrationConnectionLink({ integrationSlug: id });

  const authenticate = protectCallback((event) => {
    if (isNative()) {
      setActiveModal(CONNECT_WARNING);
      SETTINGS_MOBILE_CONNECT_MODAL.track();
    } else {
      event.preventDefault();
      connect();
    }
  });

  const handleClose = () => {
    setActiveModal(null);
    setFormData(null);
  };

  const handleShowModal = (modalId) => {
    setActiveModal(modalId);
    setFormData({ formDefaults: _.cloneDeep(config) });
  };

  const handleReset = async () => {
    await revoke(id);
    handleClose();
  };

  const sendManualImportActivities = async () => {
    if (formData?.formFields?.importActivities && hasManualImportFeature) {
      await importByDateRange({
        ...formData.formFields,
        provider,
        reprocess: true,
      });
    }
  };

  const sendManualImportFieldBoundaries = async () => {
    if (formData?.formFields?.importField) {
      await importFieldFromMachineDataProvider(provider);
    }
  };

  const handleSubmit = async () => {
    if (activeModal === AUTOMATIC_IMPORT) {
      await updateImportConfig(formData.formFields);
      handleClose();
    } else if (activeModal === MANUAL_IMPORT) {
      Promise.allSettled([sendManualImportActivities(), sendManualImportFieldBoundaries()]).then((results) => {
        const rejectedPromises = results.filter(({ status }) => status === "rejected");
        const errorMessages = rejectedPromises.map(({ reason }) => reason);
        if (errorMessages.length > 0) {
          App.notify(`Your field boundary/activities import from ${name} failed to import`);
          setActiveModal(null);
        } else {
          setActiveModal(MANUAL_IMPORT_SUCCESS);
        }
      });
    }
  };

  const isManualImportFormDisabled = () => {
    const isFieldSelected = formData?.formFields?.importField;
    const isActivitiesSelected = formData?.formFields?.importActivities;
    const isStartDate = !!formData?.formFields?.endDate;
    const isEndDate = !!formData?.formFields?.startDate;
    const fieldsConditions = [isStartDate, isEndDate, isSaving];
    const activityImportConditions = [isStartDate, isEndDate, !isSaving];
    const isActivityImportConditions = [activityImportConditions.every((x) => x), isActivitiesSelected].every((x) => x);

    const shouldEnable =
      // enable activities import
      isActivityImportConditions ||
      // enable field boundary import
      (isFieldSelected && isActivityImportConditions) ||
      (isFieldSelected && !isActivitiesSelected && fieldsConditions.every((x) => !x));
    return !shouldEnable;
  };

  return (
    <CommonIntegrationDataRow
      bordered
      copy={mainCopy}
      imgDisabled={!enabled}
      imgSrc={integrationImgSrc}
      integration={integration}
    >
      {enabled && showButtons && (
        <>
          {hasMachineData && hasManualImportFeature && (
            <LeftButton color="primary" disabled={isLoadingConfig} hollow onClick={showManualImport}>
              Manual import
            </LeftButton>
          )}

          <Button color="primary" disabled={isLoadingConfig} hollow icon="settings" onClick={showAutomaticImport} />
        </>
      )}

      {!enabled && showButtons && (
        <Button color="primary" disabled={disabled} hollow onClick={authenticate}>
          {disabled ? "Connecting..." : "Connect"}
        </Button>
      )}

      {/* Modals */}
      {activeModal === AUTOMATIC_IMPORT && (
        <DataIntegrationsModal
          disabled={isSaving}
          onClose={handleClose}
          onReset={handleReset}
          onSubmit={handleSubmit}
          resetText="Disconnect"
          submitText="Confirm changes"
          title="Automatic Data Import"
        >
          <ImportConfig
            automaticFieldImportText={automaticFieldImportText}
            automaticModalCopy={automaticModalCopy}
            data={formData}
            isAutomatic
            updateData={setFormData}
          />
        </DataIntegrationsModal>
      )}

      {activeModal === MANUAL_IMPORT && (
        <DataIntegrationsModal
          disabled={isManualImportFormDisabled()}
          onClose={handleClose}
          onSubmit={handleSubmit}
          submitText="Import data"
          title="Manual Data Import"
        >
          <ImportConfig data={formData} manualFieldImportText={manualFieldImportText} updateData={setFormData} />
        </DataIntegrationsModal>
      )}

      {activeModal === MANUAL_IMPORT_SUCCESS && (
        <DataIntegrationsModal onClose={handleClose} title="Import in progress">
          <StyledText>
            {`Your field boundary/activities import from ${name} is in progress and may take up to 4 hours. You will receive a notification when your import is complete.`}
          </StyledText>
        </DataIntegrationsModal>
      )}

      {activeModal === ACREAGE_PAYGATE && (
        <PayGateCommonBlankSlateModal banner={PayGateAdBanner} onClose={handleClose} width={960} />
      )}

      {activeModal === CONNECT_WARNING && <ConnectWarningModal onClose={handleClose} />}

      {activeModal === INTEGRATIONS_PAYGATE && (
        <PayGateCommonBlankSlateModal banner={ConnectionsBlankSlateBanner} onClose={handleClose} width={960} />
      )}
    </CommonIntegrationDataRow>
  );
};

CommonIntegration.propTypes = {
  automaticModalCopy: PropTypes.string.isRequired,
  automaticFieldImportText: PropTypes.string.isRequired,
  manualFieldImportText: PropTypes.string.isRequired,
  integrationImgSrc: PropTypes.string.isRequired,
  integration: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
  }).isRequired,
  mainCopy: PropTypes.string.isRequired,
};

export default CommonIntegration;
