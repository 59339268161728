/* eslint-disable react/display-name */
import { css, StyleSheet } from "aphrodite/no-important";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import Disclaimer from "marketing/components/Disclaimer";
import { VIEW } from "marketing/constants";
import MarketedCropFuturesAndOptions from "marketing/containers/MarketedCropFuturesAndOptions";
import CropDetailsDashboard from "marketing/dashboards/CropDetailsDashboard";
import useMarketedCropDetailContext from "marketing/hooks/useMarketedCropDetailContext";
import { getBreadCrumbLinks } from "marketing/utils/getBreadCrumbLinks";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import BreadCrumbHeader from "components/fl-ui/Layout/BreadCrumbHeader";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ErrorBoundary, { ErrorComponent } from "components/fl-ui/Layout/ErrorBoundary";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankSlate from "components/fl-ui/RestrictedAccessBlankSlate";
import Tabs, { Tab } from "components/fl-ui/Tabs";

const styles = StyleSheet.create({
  container: {
    minHeight: "100%",
  },
  contractsContainer_temp: {
    width: "100%",
    height: "100%",
    minHeight: "100%",
    padding: "1rem",
  },
  floatRight: {
    float: "right",
  },
  marginRight: {
    marginRight: "1rem !important",
  },
  headerControlsWrapper: {
    display: "flex",
  },
});

const MarketedCropDetail = ({ view }) => {
  const { marketedCrop } = useMarketedCropDetailContext();
  const navigate = useNavigate();
  useDocumentTitle(view === MarketedCropDetail.DASHBOARD ? "Marketing" : "Marketed crop contracts");

  const { loadingAccess, showPayGate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "grain_marketing",
    permissionName: "marketing",
  });

  const onTabChange = (currentTab) => {
    if (currentTab !== view) {
      switch (currentTab) {
        case MarketedCropDetail.CONTRACTS:
          navigate(`/marketing/${marketedCrop.id}/contracts`);
          break;

        case MarketedCropDetail.DASHBOARD:
          navigate(`/marketing/${marketedCrop.id}`);
          break;
      }
    }
  };

  const { allowsSpeculativeContracts, commodity, name, productionStats } = marketedCrop;
  if (view === MarketedCropDetail.CONTRACTS && !allowsSpeculativeContracts) {
    navigate(`/marketing/${marketedCrop.id}`, { replace: true });
    return null;
  }

  const breadCrumbProps = {
    action: VIEW,
    commodityName: name || commodity?.name,
    contentType: view !== "DASHBOARD" && view,
    cropId: marketedCrop.id,
    year: marketedCrop.year,
  };

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess}>
        <BreadCrumbHeader links={getBreadCrumbLinks(breadCrumbProps)} noBorder />

        {showPayGate && <PayGateAdBanner />}
        {showRestricted && <RestrictedAccessBlankSlate />}
        {showContent && (
          <>
            <div style={{ borderBottom: "1px solid #ddd" }}>
              <Tabs onChange={onTabChange} size="large" value={view}>
                <Tab value={MarketedCropDetail.DASHBOARD}>Overview</Tab>
                {allowsSpeculativeContracts && <Tab value={MarketedCropDetail.CONTRACTS}>Futures & Options</Tab>}
              </Tabs>
            </div>

            <ErrorBoundary fallback={() => <ErrorComponent />}>
              <Content style={{ marginTop: "2em" }}>
                {view === MarketedCropDetail.DASHBOARD && <CropDetailsDashboard marketedCrop={marketedCrop} />}

                {view === MarketedCropDetail.CONTRACTS && (
                  <div className={css(styles.contractsContainer_temp)}>
                    <MarketedCropFuturesAndOptions
                      commodity={commodity}
                      marketedCropId={marketedCrop.id}
                      productionStats={productionStats}
                      year={marketedCrop.year}
                    />
                  </div>
                )}
              </Content>

              {commodity?.marketSymbol && <Disclaimer />}
            </ErrorBoundary>
          </>
        )}
      </LoadingWrapper>
    </Container>
  );
};

MarketedCropDetail.propTypes = {
  view: PropTypes.oneOf(["CONTRACTS", "DASHBOARD"]).isRequired,
};

MarketedCropDetail.CONTRACTS = "CONTRACTS";
MarketedCropDetail.DASHBOARD = "DASHBOARD";

export default MarketedCropDetail;
