import { css } from "aphrodite";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import CashContractsMobileView from "contracts/components/CashContractsMobileView";
import CashContractsTableView from "contracts/components/CashContractsTableView";
import ContractsFilterBar from "contracts/components/ContractsFilterBar";
import ContractsPricingAndDeliveryModal from "contracts/components/ContractsPricingAndDeliveryModal";
import useContractPermissions from "contracts/hooks/useContractPermissions";
import useContractsIndexPage, { ContractsIndexContextProvider } from "contracts/hooks/useContractsIndexPage";
import ContractFormModal from "contracts/modals/ContractFormModal";
import { dashboardStyles as styles } from "marketing/styles";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import useViewType from "hooks/useViewType";

import ContractsBlankSlateBanner from "components/advertisements/banners/ContractsBlankSlateBanner";
import PayGateCommonBlankSlatePage from "components/advertisements/pages/PayGateCommonBlankSlatePage";
import { Button } from "components/fl-ui";
import { Container, ContentMain, Header, Stack } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankSlate from "components/fl-ui/RestrictedAccessBlankSlate";
import LoadingSpinner from "components/ui/loading";

const CashContractsContent = () => {
  const { contracts, fetchNextPage, hasMore, loading } = useContractsIndexPage();
  const isDesktop = useViewType() === "desktop";

  return (
    <ContentMain fullWidth>
      <InfiniteScroll dataLength={contracts.length} hasMore={hasMore} loader={<LoadingSpinner />} next={fetchNextPage}>
        {isDesktop && <CashContractsTableView contracts={contracts} loading={loading} />}
        {!isDesktop && <CashContractsMobileView contracts={contracts} loading={loading} />}
      </InfiniteScroll>
    </ContentMain>
  );
};

const ContractsPage = () => {
  const [showContractModal, setShowContractModal] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [newContract, setNewContract] = useState(null);
  const { canWrite } = useContractPermissions();
  useDocumentTitle("Contracts");

  const { loadingAccess, showPayGate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "contracts",
    permissionName: "contracts",
  });

  return (
    <LoadingWrapper isLoading={loadingAccess}>
      <ContractsIndexContextProvider>
        <Container>
          <Header title="Cash Contracts">
            {canWrite() && showContent && (
              <div className={css(styles.headerControlsWrapper)}>
                <Button
                  className={css(styles.addCropButton)}
                  color="primary"
                  id="add-new-crop-button"
                  onClick={() => setShowContractModal(true)}
                >
                  Add contract
                </Button>
                {showContractModal && (
                  <ContractFormModal
                    onClose={() => {
                      setShowContractModal(false);
                    }}
                    onSave={(contract) => {
                      setNewContract(contract);
                      setShowContractModal(false);
                      setShowPricingModal(true);
                    }}
                  />
                )}
                {showPricingModal && newContract && (
                  <ContractsPricingAndDeliveryModal
                    newContract={newContract}
                    onClose={() => setShowPricingModal(false)}
                    onSave={() => setShowPricingModal(false)}
                  />
                )}
              </div>
            )}
          </Header>

          {showPayGate && <PayGateCommonBlankSlatePage banner={ContractsBlankSlateBanner} />}
          {showRestricted && <RestrictedAccessBlankSlate />}
          {showContent && (
            <Stack space="2rem">
              <ContractsFilterBar />
              <CashContractsContent />
            </Stack>
          )}
        </Container>
      </ContractsIndexContextProvider>
    </LoadingWrapper>
  );
};

export default ContractsPage;
