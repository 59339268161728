/* eslint-disable react/display-name */
import useDocumentTitle from "app/hooks/useDocumentTitle";
import InventoryTransaction from "loads/components/InventoryTransaction";
import DeliveryTable from "loads/components/delivery_table";
import { FullWidthButton } from "loads/components/styledComponents";
import moment from "moment";
import React from "react";
import styled from "styled-components";

import { useAuth } from "collection/graphql/auth";
import Transactions from "collection/inventory_transactions";
import { readCurrentCropYear } from "hooks/useCurrentCropYear";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import useFieldCrops from "modules/fields/hooks/useFieldCrops";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import { Button } from "components/fl-ui";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ContentMain from "components/fl-ui/Layout/ContentMain";
import ContentSidebar from "components/fl-ui/Layout/ContentSidebar";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { RestrictedAccessWithHeader } from "components/fl-ui/RestrictedAccessWithHeader";
import { DatePicker } from "components/fl-ui/common";
import Sidebar from "components/ui/sidebar";

const DateRangeContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  > :nth-child(2) {
    margin: 0 8px;
  }
`;

const show = 10;

const withHooks = (Component) => (props) => {
  useDocumentTitle("Loads list");
  const { loading: authLoading } = useAuth();
  const { getCropByInventoryNode, loading: cropsLoading } = useFieldCrops();
  const loading = authLoading || cropsLoading;

  const { loadingAccess, showPayGate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "legacy_storage_and_loads",
    permissionName: "scale_tickets",
  });

  return (
    <LoadingWrapper isLoading={loading || loadingAccess}>
      {showPayGate && <PayGateAdBanner />}
      {showRestricted && <RestrictedAccessWithHeader title="Loads" />}
      {showContent && <Component {...props} getCropByInventoryNode={getCropByInventoryNode} />}
    </LoadingWrapper>
  );
};

class Loads extends React.Component {
  getLoads = () => Transactions.getDeliveries(this.props.getCropByInventoryNode);

  state = {
    dates: Transactions.getDateRange(),
    dateUpdated: false,
    end: moment(Transactions.getDateRange().endDate),
    filterState: { commodity_id: null },
    loadsAll: this.getLoads(),
    showMore: { display: "none" },
    start: moment(Transactions.getDateRange().startDate),
    year: readCurrentCropYear(),
  };

  componentDidMount() {
    Transactions.on("add change remove sync destroy", this.updateLoadsAll, this);
    this.serializeData();
  }

  componentWillUnmount() {
    Transactions.off(null, null, this);
  }

  updateLoadsAll = () => {
    this.setState({ loadsAll: this.getLoads() }, this.serializeData);
  };

  _handleFilterChange = ({ commodity_id }) => {
    this.setState(({ filterState }) => ({ filterState: { ...filterState, commodity_id } }), this.serializeData);
  };

  serializeData = () => {
    const compareStart = moment(this.state.start).format("YYYY-MM-DD");
    const compareEnd = moment(this.state.end).format("YYYY-MM-DD");

    const loads = this.state.loadsAll
      .filter((load) => [null, load.commodity_id].includes(this.state.filterState.commodity_id))
      .filter((load) => load.date >= compareStart && load.date <= compareEnd);

    if (loads.length > show) {
      this.setState({
        loads: loads.slice(0, show),
        showMore: { display: "block" },
      });
    } else {
      this.setState({
        loads,
        showMore: { display: "none" },
      });
    }
  };

  onDateChange = (date) => {
    this.setState({
      dateUpdated: true,
      ...date,
    });
    this.serializeData();
  };

  showMore = () => {
    this.setState({
      loads: this.state.loadsAll,
      showMore: { display: "none" },
    });
  };

  handleRowClick = (loadId) => {
    this.setState({ loadData: Transactions.get(loadId)?.toJSON() });
  };

  render() {
    return (
      <Container>
        <Header title="Loads" />
        <Content>
          <ContentSidebar>
            <FullWidthButton color="primary" onClick={() => this.setState({ loadData: {} })}>
              Log Load
            </FullWidthButton>

            <Sidebar filterState={this.state.filterState} onChange={this._handleFilterChange} year={this.state.year} />
          </ContentSidebar>

          <ContentMain>
            <DateRangeContainer>
              <DatePicker
                onChange={(start) => this.onDateChange({ start })}
                outputFormat="M/d/yyyy"
                placeholder="Start date"
                value={this.state.start}
              />
              <span> - </span>
              <DatePicker
                onChange={(end) => this.onDateChange({ end })}
                outputFormat="M/d/yyyy"
                placeholder="End date"
                value={this.state.end}
              />
            </DateRangeContainer>
            <div className="js-deliveries">
              <br />
              <br />
              {this.state.loads?.length ? (
                <div>
                  <DeliveryTable loads={this.state.loads} onRowClick={this.handleRowClick} />
                  <span
                    className="js-show-more btn btn-block-small"
                    onClick={this.showMore}
                    style={this.state.showMore}
                  >
                    Show more...
                  </span>
                </div>
              ) : (
                <div className="blank-slate-contain yield">
                  <img alt="Track your harvest storage and delivery" src="/images/blank_slate/yield-blank-slate.png" />
                  <h2>Track your harvest storage and delivery</h2>
                  <Button className="btn btn-smoke btn-block-small" onClick={() => this.setState({ loadData: {} })}>
                    Log a load
                  </Button>
                </div>
              )}
            </div>
          </ContentMain>
        </Content>

        {this.state.loadData && (
          <InventoryTransaction load={this.state.loadData} onClose={() => this.setState({ loadData: null })} />
        )}
      </Container>
    );
  }
}

export default withHooks(Loads);
