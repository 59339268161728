import _ from "lodash";
import React, { useReducer } from "react";
import ReactSelect from "react-select";
import styled from "styled-components";

import ActivitiesBlankSlateBanner from "components/advertisements/banners/ActivitiesBlankSlateBanner";
import ConnectionsBlankSlateBanner from "components/advertisements/banners/ConnectionsBlankSlateBanner";
import FileAttachmentsBlankSlateBanner from "components/advertisements/banners/FileAttachmentsBlankSlateBanner";
import ImageryBlankSlateBanner from "components/advertisements/banners/ImageryBlankSlateBanner";
import LocalPricesBlankSlateBanner from "components/advertisements/banners/LocalPricesBlankSlateBanner";
import MarketingBlankSlateBanner from "components/advertisements/banners/MarketingBlankSlateBanner";
import OtherExpensesAndRevenueBlankSlateBanner from "components/advertisements/banners/OtherExpensesAndRevenueBlankSlateBanner";
import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import ProfitAndLossBlankSlateBanner from "components/advertisements/banners/ProfitAndLossBlankSlateBanner";
import RainAndHeatBlankSlateBanner from "components/advertisements/banners/RainAndHeatBlankSlateBanner";
import ReportsBlankSlateBanner from "components/advertisements/banners/ReportsBlankSlateBanner";
import SoilBlankSlateBanner from "components/advertisements/banners/SoilBlankSlateBanner";
import ActivitiesBlankSlatePage from "components/advertisements/pages/ActivitiesBlankSlatePage";
import FileAttachmentsBlankSlatePage from "components/advertisements/pages/FileAttachmentsBlankSlatePage";
import LocalPricesBlankSlatePage from "components/advertisements/pages/LocalPricesBlankSlatePage";
import MarketingBlankSlatePage from "components/advertisements/pages/MarketingBlankSlatePage";
import ProfitAndLossBlankSlateBannerPage from "components/advertisements/pages/ProfitAndLossBlankSlateBannerPage";
import RainAndHeatBlankSlatePage from "components/advertisements/pages/RainAndHeatBlankSlatePage";
import ReportsBlankSlatePage from "components/advertisements/pages/ReportsBlankSlatePage";
import { Button } from "components/fl-ui";
import { TagSelectGroup } from "components/fl-ui/Form";

const banners = {
  ActivitiesBlankSlateBanner,
  ConnectionsBlankSlateBanner,
  FileAttachmentsBlankSlateBanner,
  ImageryBlankSlateBanner,
  LocalPricesBlankSlateBanner,
  MarketingBlankSlateBanner,
  OtherExpensesAndRevenueBlankSlateBanner,
  PayGateAdBanner,
  ProfitAndLossBlankSlateBanner,
  RainAndHeatBlankSlateBanner,
  ReportsBlankSlateBanner,
  SoilBlankSlateBanner,
};

const pages = {
  ActivitiesBlankSlatePage,
  FileAttachmentsBlankSlatePage,
  LocalPricesBlankSlatePage,
  MarketingBlankSlatePage,
  ProfitAndLossBlankSlateBannerPage,
  RainAndHeatBlankSlatePage,
  ReportsBlankSlatePage,
};

const bannerOptions = _.keys(banners).map((value) => ({ label: value, value }));
const pageOptions = _.keys(pages).map((value) => ({ label: value, value }));

const reducer = (state, { direction, ...action }) => {
  const newState = { ...state, ...action };

  if ("activeFilter" in action) {
    newState.componentOptions = newState.activeFilter === "banners" ? bannerOptions : pageOptions;
    newState.selectedComponent = newState.componentOptions[0];
  }

  const currentIndex = newState.componentOptions.indexOf(newState.selectedComponent);
  if (direction === "next") {
    let nextIndex = currentIndex + 1;
    if (nextIndex >= newState.componentOptions.length) {
      nextIndex = 0;
    }
    newState.selectedComponent = newState.componentOptions[nextIndex];
  } else if (direction === "previous") {
    let previousIndex = currentIndex - 1;
    if (previousIndex < 0) {
      previousIndex = newState.componentOptions.length - 1;
    }
    newState.selectedComponent = newState.componentOptions[previousIndex];
  }

  const components = newState.activeFilter === "banners" ? banners : pages;
  newState.Component = components[newState.selectedComponent.value];

  return newState;
};

const Controls = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  > :first-child {
    flex-grow: 2;
  }
`;

const ControlLabel = styled.div`
  color: #454545;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.375;
`;

const PayGateDemos = () => {
  const [state, setState] = useReducer(reducer, {}, () => {
    return {
      activeFilter: "banners",
      Component: banners[bannerOptions[0].value],
      componentOptions: bannerOptions,
      filterOptions: [
        { id: "banners", label: "Banners", value: "banners" },
        { id: "pages", label: "Pages", value: "pages" },
      ],
      selectedComponent: bannerOptions[0],
    };
  });

  const { activeFilter, Component, componentOptions, filterOptions, selectedComponent } = state;

  const handleComponentChange = (selectedComponent) => setState({ selectedComponent });
  const handleFilterChange = (value) => setState({ activeFilter: value });

  return (
    <div>
      <h3>Pay Gates/Advertisements</h3>

      <TagSelectGroup
        id="filters"
        label="Ad Type"
        onChange={handleFilterChange}
        options={filterOptions}
        value={activeFilter}
      />

      <ControlLabel>Component</ControlLabel>
      <Controls>
        <ReactSelect onChange={handleComponentChange} options={componentOptions} value={selectedComponent} />

        <div>
          <Button icon="chevronLeft" onClick={() => setState({ direction: "previous" })} />{" "}
          <Button icon="chevronRight" onClick={() => setState({ direction: "next" })} />
        </div>
      </Controls>

      <Component />
    </div>
  );
};

export default PayGateDemos;
