import { useQuery } from "@apollo/client";
import EquipmentListing from "equipment/components/EquipmentListing";
import MaintenanceRecordContainer from "equipment/containers/MaintenanceRecordContainer";
import ImplementModal from "equipment/forms/ImplementModal";
import TractorModal from "equipment/forms/TractorModal";
import _ from "lodash";
import React, { useState } from "react";

import { getAllEquipment } from "collection/graphql/equipment/queries";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import { Button } from "components/fl-ui";
import { BreadCrumbHeader, Cluster, Container, Stack } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankSlate from "components/fl-ui/RestrictedAccessBlankSlate";

const IndexPage = () => {
  const { loadingAccess, showPayGate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "equipment",
    permissionName: "equipment",
  });
  const { data, loading } = useQuery(getAllEquipment, { fetchPolicy: "cache-and-network" });
  const allImplements = _.sortBy(data?.allImplements, "name");
  const allTractors = _.sortBy(data?.allTractors, "name");
  const [shownModal, setShownModal] = useState(null);
  const [record, setRecord] = useState(null);

  const handleImplementAdd = () => setShownModal("implement");
  const handleTractorAdd = () => setShownModal("tractor");

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess || (loading && !data)}>
        <BreadCrumbHeader links={[{ label: "Equipment" }]} />

        {showPayGate && <PayGateAdBanner />}
        {showRestricted && <RestrictedAccessBlankSlate />}
        {showContent && (
          <Stack space="2rem">
            <Stack space="1.5rem">
              <Cluster between>
                <h2>Tractors</h2>
                <Button onClick={handleTractorAdd}>New tractor</Button>
              </Cluster>

              <EquipmentListing onAdd={handleTractorAdd} listItems={allTractors} type="tractor" />
            </Stack>

            <Stack space="1.5rem">
              <Cluster between>
                <h2>Implements</h2>
                <Button onClick={handleImplementAdd}>New implement</Button>
              </Cluster>

              <EquipmentListing onAdd={handleImplementAdd} listItems={allImplements} type="implement" />
            </Stack>

            <Stack space="1.5rem">
              <Cluster between>
                <h2>Maintenance</h2>
                <Button onClick={() => setRecord({})}>Log maintenance</Button>
              </Cluster>

              <MaintenanceRecordContainer record={record} setRecord={setRecord} />
            </Stack>
          </Stack>
        )}
      </LoadingWrapper>

      {shownModal === "implement" && (
        <ImplementModal
          onClose={() => setShownModal(null)}
          onDelete={() => {
            setShownModal(null);
          }}
          onSaveSuccess={() => {
            setShownModal(null);
          }}
        />
      )}

      {shownModal === "tractor" && (
        <TractorModal
          onClose={() => setShownModal(null)}
          onDelete={() => {
            setShownModal(null);
          }}
          onSaveSuccess={() => {
            setShownModal(null);
          }}
        />
      )}
    </Container>
  );
};

IndexPage.screenName = "Equipment";
IndexPage.title = "Equipment";

export default IndexPage;
