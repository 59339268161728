import useDocumentTitle from "app/hooks/useDocumentTitle";
import numeral from "numeral";
import React, { useEffect, useMemo, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "underscore";

import useCropYears from "collection/graphql/fields/hooks/useCropYears";
import withCropMutation from "hoc/withCropMutation";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";

import RainAndHeatBlankSlatePage from "components/advertisements/pages/RainAndHeatBlankSlatePage";
import CropHistory from "components/field/CropHistory";
import CropStatus from "components/field/CropStatus";
import FieldAttachmentButton from "components/field/FieldAttachmentButton";
import FieldAttachments from "components/field/FieldAttachments";
import Loads from "components/field/Loads";
import ScoutingNotes from "components/field/ScoutingNotes";
import Yield from "components/field/Yield";
import FieldActivities from "components/field/activities";
import useWeatherSummary from "components/field/weather/hooks/useWeatherSummary";
import { Button } from "components/fl-ui";
import { Container, Content, ContentMain, Header } from "components/fl-ui/Layout";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import PayGatedScoutingNotes from "components/fl-ui/ScoutingNotes/PayGatedScoutingNotes";
import YearPicker from "components/fl-ui/YearPicker";
import HeatDropdown, { getDefaultFormula } from "fields/components/HeatDropdown";
import FieldHeatContext from "fields/components/context/FieldHeatContext";
import useFieldAccess from "fields/hooks/useFieldAccess";

const sectionsWithYearPicker = ["rainfall", "gdd"];
const heatReducer = (state, { type, ...payload }) => {
  if (type === "setHeatRange") {
    return {
      ...state,
      tbase: payload.tbase || undefined,
      tceil: payload.tceil || undefined,
    };
  }

  return state;
};

/**
 * @param {"activities"|"attachments"|"crop_history"|"gdd"|"loads"|"rainfall"|"scouting"|"yield"} aspect
 * @return {Function}
 */
const getAspectSpecificComponent = (aspect) => {
  const components = {
    activities: FieldActivities,
    attachments: FieldAttachments,
    crop_history: CropHistory,
    gdd: CropStatus,
    loads: Loads,
    rainfall: CropStatus,
    scouting: ScoutingNotes,
    yield: Yield,
  };
  if (!(aspect in components)) {
    throw new Error(`Unknown aspect: "${aspect}"`);
  }

  return components[aspect];
};

/**
 * @param {"activities"|"attachments"|"crop_history"|"gdd"|"loads"|"rainfall"|"scouting"|"yield"} aspect
 * @return {string}
 */
const getAspectSpecificTitle = (aspect) => {
  const titleMap = {
    activities: "Activities",
    attachments: "Attachments",
    crop_history: "Crop History",
    gdd: "Heat Units",
    loads: "Loads",
    rainfall: "Rainfall",
    scouting: "Scouting",
    yield: "Yield",
  };
  if (!(aspect in titleMap)) {
    throw new Error(`Unknown aspect: "${aspect}"`);
  }

  return titleMap[aspect];
};

const withHeatReducer = (Component) => (props) => {
  const { aspect } = useParams();
  const fieldId = +useParams().id;
  const cropYears = useCropYears({ fieldId });
  const { field, loading } = useFieldAccess(fieldId);

  const [state, dispatch] = useReducer(heatReducer, {
    tbase: getDefaultFormula().tbase,
    tceil: getDefaultFormula().tceil,
  });

  const year = useCurrentCropYear()[0];
  const { gddTbase, gddTceil } = useWeatherSummary(fieldId, year, "aspect").data ?? {};

  useDocumentTitle(field?.name ? `${getAspectSpecificTitle(aspect)} on ${field.name}` : "Field");

  useEffect(() => {
    if (gddTbase) {
      dispatch({
        type: "setHeatRange",
        tbase: gddTbase || undefined,
        tceil: gddTceil || (gddTbase === 50 ? 86 : undefined),
      });
    }
  }, [gddTbase, gddTceil]);

  return loading && !field ? (
    <LoadingWrapper />
  ) : (
    <Component {...props} cropYears={cropYears} dispatch={dispatch} field={field} state={state} year={year} />
  );
};

const FieldAspect = withHeatReducer(
  withCropMutation((props) => {
    const { createCrop, cropYears, dispatch, field, state, year } = props;
    const { aspect } = useParams();
    const navigate = useNavigate();

    const getYearPickerProps = () => {
      const disabledYears = new Set();
      const years = _.map(cropYears, ({ used, year }) => {
        if (!used) {
          disabledYears.add(year);
        }

        return year;
      })
        .sort()
        .reverse();

      // gets rid of 2016 from years for rainfall and gdd data
      if (sectionsWithYearPicker.includes(aspect)) {
        years.shift();
      }

      return {
        disabledYears,
        years,
      };
    };

    const goBack = () => {
      navigate(`/fields/${field.id}`, { trigger: true });
    };

    const aspectTitle = useMemo(() => getAspectSpecificTitle(aspect), [aspect]);
    const ChildComponent = useMemo(() => getAspectSpecificComponent(aspect), [aspect]);
    const formattedAcreage = numeral(field.acreage).format("0[.]0");
    let pageWrapperClass = "narrow-page-wrapper";
    if (["gdd", "rainfall"].includes(aspect)) {
      pageWrapperClass = "page-wrapper-larger";

      if (!useEnterpriseFeature("weather_history")) {
        return (
          <Container>
            <Header title="Rainfall" />
            <Content>
              <RainAndHeatBlankSlatePage />
            </Content>
          </Container>
        );
      }
    }

    const fieldHeatContextValue = useMemo(() => ({ dispatch, state }), [dispatch, state]);

    return (
      <FieldHeatContext.Provider value={fieldHeatContextValue}>
        <Container className={pageWrapperClass}>
          <Header title={`${aspectTitle} at ${field.name}`} description={`${formattedAcreage} ac`} backLink={goBack}>
            <div>{year && sectionsWithYearPicker.includes(aspect) && <YearPicker {...getYearPickerProps()} />}</div>

            {aspect === "attachments" && <FieldAttachmentButton fieldId={field.id} />}

            {aspect === "gdd" && (
              <span style={{ marginLeft: "1rem" }}>
                <HeatDropdown />
              </span>
            )}

            {aspect === "scouting" && (
              <PayGatedScoutingNotes fieldId={field.id}>
                {(handleNavigate) => (
                  <Link
                    className="btn"
                    to={`/scouting/add?field_id=${field.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavigate();
                    }}
                  >
                    Add note
                  </Link>
                )}
              </PayGatedScoutingNotes>
            )}

            {aspect === "crop_history" && <Button onClick={() => createCrop(field.id)}>Add crop</Button>}
          </Header>

          <Content>
            <ContentMain fullWidth>
              <ChildComponent
                {...props}
                aspect={aspect}
                fieldId={field.id}
                fieldName={field.name}
                showAll
                year={sectionsWithYearPicker.includes(aspect) ? year : null}
              />
            </ContentMain>
          </Content>
        </Container>
      </FieldHeatContext.Provider>
    );
  })
);

export default FieldAspect;
