import React from "react";
import { useAuth as useSSOAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";
import BaseSSOPageContainer from "sso/components/BaseSSOPageContainer";
import styled from "styled-components";

import { useAuth } from "collection/graphql/auth";

import { Button } from "components/fl-ui";
import { GreyColors } from "components/fl-ui/colors";
import { Spacing } from "components/fl-ui/constants";

const SignIn = styled(({ className }) => {
  const auth = useSSOAuth();
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  const goToBushelIdentity = () => {
    void auth.signinRedirect({
      show_cancel_button: true,
    });
  };

  return (
    <BaseSSOPageContainer title="Next Generation Farm Management&nbsp;Software">
      <div className={className}>
        <Button className="sign-in-sign-up" color="primary" onClick={goToBushelIdentity}>
          Sign In or Sign Up
        </Button>
        <div className="grey-text">
          <p>
            {`By creating a Bushel Farm account, you are accepting Bushel's `}
            <a href="https://bushelpowered.com/trust-center/" target="_blank" rel="noreferrer">
              privacy policy and terms of use
            </a>
            .
          </p>
        </div>
      </div>
    </BaseSSOPageContainer>
  );
})`
  width: 100%;

  .sign-in-sign-up {
    margin: ${Spacing.regular} 0;
    width: 100%;
  }

  .grey-text {
    color: ${GreyColors.smoke70};
    font-size: 0.8rem;
    margin-top: ${Spacing.large};

    a {
      color: ${GreyColors.smoke70};
      text-decoration: underline;
    }
  }
`;

export default SignIn;
