import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useState } from "react";
import DataIntegrationsModal from "settings/components/DataIntegrationsModal";
import { List, OrderedList, StyledText } from "settings/styledComponents";

import { UPLOAD_MACHINE_DATA } from "collection/graphql/yield/mutations";
import useEnterpriseFeature from "hooks/useEnterpriseFeature";
import App from "layout/app";

import YieldDataBlankSlateBanner from "components/advertisements/banners/YieldDataBlankSlateBanner";
import PayGateCommonBlankSlateModal from "components/advertisements/modals/PayGateCommonBlankSlateModal";
import InlineFilePicker from "components/fl-ui/Attachments/InlineFilePicker";

const YieldUpload = ({ onClose }) => {
  const [createMachineDataFile] = useMutation(UPLOAD_MACHINE_DATA);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const hasMachineData = useEnterpriseFeature("machine_data");

  if (!hasMachineData) {
    return <PayGateCommonBlankSlateModal banner={YieldDataBlankSlateBanner} onClose={onClose} width={960} />;
  }

  if (showSuccessModal) {
    return (
      <DataIntegrationsModal onClose={onClose} title="Your machine data import has started">
        <StyledText>
          {`We're processing your files now. Machine data imports require conversion and typically take 30 minutes or more. You'll receive an alert once your import is complete.`}
        </StyledText>
      </DataIntegrationsModal>
    );
  }

  return (
    <DataIntegrationsModal onClose={onClose} title="Upload Machine Data">
      <StyledText>
        Exports of machine data from your equipment monitors or other agronomic software can be imported to your Bushel
        Farm by following the instructions below:
      </StyledText>

      <OrderedList>
        <List>
          <StyledText>
            <strong>Export your data</strong>
          </StyledText>

          <StyledText>
            {`Follow the manufacturer's instructions for exporting files containing `}
            <code>as-planted</code>, <code>as-applied</code> or <code>yield data</code>.
          </StyledText>
        </List>

        <List>
          <StyledText>
            <strong>Create a .zip of your files</strong>
          </StyledText>

          <StyledText>
            {"Follow "}
            <a href="https://www.wikihow.com/Make-a-Zip-File" rel="noopener noreferrer" target="_blank">
              these
            </a>
            {" instructions to create a zip file that you can upload on this page. "}
          </StyledText>
        </List>

        <List>
          <StyledText>
            <strong>Upload your zip file</strong>
          </StyledText>

          <InlineFilePicker
            onError={(error) => {
              App.notify("An error occurred, please try again.");
              throw error;
            }}
            onSuccess={({ filesUploaded }) => {
              if (filesUploaded?.length) {
                const { filename, url } = filesUploaded[0];
                const file = { name: filename, url };
                createMachineDataFile({ variables: { file } }).then(() => setShowSuccessModal(true));
              }
            }}
            pickerOptions={{ accept: ["application/zip", "application/x-zip-compressed"] }}
          />
        </List>
      </OrderedList>
    </DataIntegrationsModal>
  );
};

YieldUpload.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default YieldUpload;
